/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';

import './ml.styl';

import MainLayout from '../views/layouts/MainLayout';
import Parallax from '../views/components/Parallax';
import SEO from '../views/components/SEO';

const MLPage = ({ pageContext: { locale, originalPath }, location, data }) => {
    const site = (
        <a
            href='https://groupe-editor.com'
            target='_blank'
            rel='noreferrer noopener'
        >
            www.groupe-editor.com
        </a>
    );
    const cnilLink = (
        <a
            href='http://www.cnil.fr/vos-droits/vos-traces/les-cookies/'
            target='_blank'
            rel='noreferrer noopener'
        >
            http://www.cnil.fr/vos-droits/vos-traces/les-cookies/
        </a>
    );
    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
        >
            <SEO
                title='pages.ml.headerTitle'
                description='pages.ml.metaDescription'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
            />
            <div className='page-bg page-ml'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-mentions-légales-groupe-editor'
                    overlay='light'
                >
                    <div className='page-intro contact'>
                        <div className='container'>
                            <div className='left'>
                                <FormattedMessage
                                    tagName='h1'
                                    id='pages.ml.title'
                                />
                            </div>
                        </div>
                    </div>
                </Parallax>
                <div className='page-content'>
                    <div className='container'>
                        <section>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.intro'
                                    values={{ site }}
                                />
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.editor.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.editor.p'
                                        values={{ br: <br /> }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.owner.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.owner.p'
                                        values={{ br: <br /> }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.hosting.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.hosting.p'
                                        values={{ br: <br /> }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.cgu.title'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.cgu.p'
                                        values={{ site }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.access.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.access.p'
                                        values={{ site }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.copyright.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.copyright.p1'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.copyright.p2'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.copyright.p3'
                                        values={{ site, supp: <sup>er</sup> }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.copyright.p4'
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.links.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.links.p1'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.links.p2'
                                        values={{ site }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.photo.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.photo.p'
                                    />
                                </div>
                                <div id='user-data'>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.userData.title'
                                    />
                                    <FormattedMessage
                                        tagName='h4'
                                        id='pages.ml.section.userData.collected.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.collected.p'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='h4'
                                        id='pages.ml.section.userData.access.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.access.p1'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.access.p2'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.access.p3'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='h4'
                                        id='pages.ml.section.userData.cookie.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.cookie.p1'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.cookie.p2'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='h5'
                                        id='pages.ml.section.userData.cookie.moz.title'
                                    />
                                    <ol>
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.moz.l1'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.moz.l2'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.moz.l3'
                                        />
                                    </ol>
                                    <FormattedMessage
                                        tagName='h5'
                                        id='pages.ml.section.userData.cookie.ie.title'
                                    />
                                    <ol>
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.ie.l1'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.ie.l2'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.ie.l3'
                                        />
                                    </ol>
                                    <FormattedMessage
                                        tagName='h5'
                                        id='pages.ml.section.userData.cookie.opera.title'
                                    />
                                    <ol>
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.opera.l1'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.opera.l2'
                                        />
                                    </ol>
                                    <FormattedMessage
                                        tagName='h5'
                                        id='pages.ml.section.userData.cookie.chrome.title'
                                    />
                                    <ol>
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.chrome.l1'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.chrome.l2'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.chrome.l3'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.chrome.l4'
                                        />
                                        <FormattedMessage
                                            tagName='li'
                                            id='pages.ml.section.userData.cookie.chrome.l5'
                                        />
                                    </ol>
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.cookie.p3'
                                        values={{ site: cnilLink }}
                                    />
                                    <div>
                                        <FormattedMessage
                                            tagName='h4'
                                            id='pages.ml.section.userData.socials.title'
                                        />
                                        <FormattedMessage
                                            tagName='p'
                                            id='pages.ml.section.userData.socials.p'
                                            values={{ site }}
                                        />
                                    </div>
                                    <div>
                                        <FormattedMessage
                                            tagName='h4'
                                            id='pages.ml.section.userData.personnal.title'
                                        />
                                        <FormattedMessage
                                            tagName='p'
                                            id='pages.ml.section.userData.personnal.p1'
                                            values={{ site }}
                                        />
                                        <FormattedMessage
                                            tagName='p'
                                            id='pages.ml.section.userData.personnal.p2'
                                            values={{ site }}
                                        />
                                    </div>
                                    <div>
                                        <FormattedMessage
                                            tagName='h3'
                                            id='pages.ml.section.userData.right.title'
                                        />
                                        <FormattedMessage
                                            tagName='p'
                                            id='pages.ml.section.userData.right.p'
                                            values={{ site }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

MLPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default MLPage;

export const query = graphql`
    query {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "ml.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "ml.jpg" }
        ) {
            ...SeoImages
        }
    }
`;
